import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/adminAPI"




// ========= forgot-Passsword ==============

export const forgotPassword = createAsyncThunk(
    "forgotPassword",
    async(details) => {
        const data = await AdminAPI.post(`/auth/forgetpassword`,details);
  
       return data
    } 
);

//  ========== verify Otp ===========

export const verifyotp = createAsyncThunk(
    "verifyotp",
    async(details) => {
        const data = await AdminAPI.post(`/auth/verifyotp`,details);
  
       return data;
    } 
);

// ================== Reset Password =======================

export const resetPassword = createAsyncThunk(
    "resetPassword",
    async(details) => {
        const data = await AdminAPI.post(`/auth/resetPassword`,details);
  
       return data;
    } 
);

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
    "chnagePassword",
    async(details) => {
        const data = await AdminAPI.patch(`/auth/changePassword`,details);
  
       return data;
    } 
);

// ================== get Dashboard =========================

export const getDashboard = createAsyncThunk(
    "getDashboard",
    async(details) => {
        const data = await AdminAPI.get(`/dashboard`,details);
  
       return data;
    } 
);
 // ================= get Usermanagement =====================

 export const usermanagement = createAsyncThunk(
    "usermanagement",
    async (details) => {
      let url = `/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;
  
      if (details.search) {
        url += `&name=${details.search}`;
      }
  
      const data = await AdminAPI.get(url);

      return data;
    }
  );


    // dowanload xlsv for all usermanagent all users

    export const dowanloadusermgmtXlsv = createAsyncThunk(
        "dowanloadusermgmtXlsv",
        async(details) => {
            const data = await AdminAPI.get(`/userManagement?type=${details?.type}`);
      
           return data;
        } 
    );

    // ======== BanOrUnban user Profile Action =================

export const BanOrUnBan = createAsyncThunk(
    "BanOrUnBan",
    async(details) => {
        const data = await AdminAPI.put(`/banUnbanUser`, details);
  
       return data;
    } 
);

// get customer profile by id 

export const getCustomerdetails = createAsyncThunk(
    "getCustomerdetails",
    async(details) => {
        const data = await AdminAPI.get(`/userProfile?userId=${details?.id}`);
  
       return data;
    } 
);

// ================ get user activity ===================

export const  getActivity = createAsyncThunk(
    "getActivity",
    async(details)=> {
       const {data} = await AdminAPI.get(`/getActivities?user_id=${details?.id}`)
       return data;
    }
  );

  // =========== Susbend account =============

export const suspendAccount = createAsyncThunk(
    "suspendAccount",
    async(details) => {
        const data = await AdminAPI.put(`/banUnbanUser`, details);
  
       return data;
    } 
);

// =========== Delete account =============

export const DeleteAccount = createAsyncThunk(
    "suspendAccount",
    async(details) => {
        const data = await AdminAPI.patch(`/deleteAccount?userId=${details?.id}`);
  
       return data;
    } 
  );

//   // ========= Get banuser Listing ============

// export const getBannedUser = createAsyncThunk(
//     "getBannedUser",
//     async (details) => {
//       let url = `/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;
  
//       if (details.search) {
//         url += `&name=${details.search}`;
//       }
  
//       const data = await AdminAPI.get(url);

//       return data;
//     }
//   );

    // ============ Dowanload xlsv BanedUser=========


//     export const dowanloadBanXlsv = createAsyncThunk(
//         "dowanloadBanXlsv",
//         async(details) => {
//             const data = await AdminAPI.get(`/userManagement`,details);
      
//            return data;
//         } 
//     );



//     // ================ get Active USers Data =====================

// export const getActiveUsers = createAsyncThunk(
//     "getActiveUsers",
//     async (details) => {
//       let url = `/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;
  
//       if (details.search) {
//         url += `&name=${details.search}`;
//       }
  
//       const data = await AdminAPI.get(url);

//       return data;
//     }
//   );


//     // ============ Dowanload xlsv Activeuser =========


//     export const dowanloadActiveXlsv = createAsyncThunk(
//       "dowanloadActiveXlsv",
//       async(details) => {
//           const data = await AdminAPI.get(`/userManagement`,details);
    
//          return data;
//       } 
//   );

  // ============== get players list =================

  export const ALLPlayersList = createAsyncThunk(
    "ALLPlayersList",
    async (details) => {
      let url = `/getPlayers?page=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&name=${details.search}`;
      }
  
      const data = await AdminAPI.get(url);

      return data;
    }
  );

  // ========= Dowanload Players xlsx file ===========

  export const dowanloadPlayersXlsx = createAsyncThunk(
    "dowanloadPlayersXlsx",
    async(details) => {
        const data = await AdminAPI.get(`/getPlayers`,details);
  
       return data;
    } 
);

// =========== Get player profile By Id ==========

export const getPlayersdetails = createAsyncThunk(
  "getPlayersdetails",
  async(details) => {
      const data = await AdminAPI.get(`/getPlayerById?playerId=${details?.id}`);

     return data;
  } 
);

// ======== Remove Player =============

export const RemovePlayer = createAsyncThunk(
  "RemovePlayer",
  async(details) => {
      const data = await AdminAPI.delete(`/deletePlayer?playerId=${details?.playerId}`);

     return data;
  } 
);


// ======== Get all Directors =========


export const AllDirectors = createAsyncThunk(
  "AllDirectors",
  async (details) => {
    let url = `/getDirectors?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);

  // ========= Dowanload Directors xlsx file ===========

  export const DowanloadDirectors = createAsyncThunk(
    "DowanloadDirectors",
    async(details) => {
        const data = await AdminAPI.get(`/getDirectors`,details); 
       return data;
    } 
);


// ======== Delete Director =============

export const DeleteDirector = createAsyncThunk(
  "DeleteDirector",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteDirector?directorId=${details?.directorId}`);
     return data;
  } 
);


// =========== Get Directors profile By Id ==========

export const getDirectorsdetails = createAsyncThunk(
  "getDirectorsdetails",
  async(details) => {
      const data = await AdminAPI.get(`/getDirectorById?directorId=${details?.id}`);

     return data;
  } 
);


// ======== Get all Coaches =========


export const AllCoaches = createAsyncThunk(
  "AllCoaches",
  async (details) => {
    let url = `/getCoaches?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);

  // ========= Dowanload Coaches xlsx file ===========

  export const DowanloadCoaches = createAsyncThunk(
    "DowanloadCoaches",
    async(details) => {
        const data = await AdminAPI.get(`/getCoaches`,details); 
       return data;
    } 
);


// ======== Delete Coaches =============

export const DeleteCoaches = createAsyncThunk(
  "DeleteCoaches",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteCoach?coachId=${details?.coachId}`);

     return data;
  } 
);


// =========== Get Directors profile By Id ==========

export const getCoachdetails = createAsyncThunk(
  "getCoachdetails",
  async(details) => {
      const data = await AdminAPI.get(`/getCoachById?coachId=${details?.id}`);

     return data;
  } 
);


// ======== Get all Agents =========

export const AllAgents = createAsyncThunk(
  "AllAgents",
  async (details) => {
    let url = `/getAgents?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);

  // ========= Dowanload Agents xlsx file ===========

  export const DowanloadAgents = createAsyncThunk(
    "DowanloadAgents",
    async(details) => {
        const data = await AdminAPI.get(`/getAgents`,details); 
       return data;
    } 
);


// ======== Delete Coaches =============

export const DeleteAgent = createAsyncThunk(
  "DeleteAgent",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteAgent?agentId=${details?.agentId}`);

     return data;
  } 
);


// =========== Get Agent profile By Id ==========

export const getAgentdetails = createAsyncThunk(
  "getAgentdetails",
  async(details) => {
      const data = await AdminAPI.get(`/getAgentById?agentId=${details?.id}`);

     return data;
  } 
);


// ======== Get all Other Agents =========  

export const AllOtherAgents = createAsyncThunk(
  "AllOtherAgents",
  async (details) => {
    let url = `/getOtherAgents?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);


  // ========= Dowanload Other Agents xlsx file ===========

  export const DowanloadOtherAgents = createAsyncThunk(
    "DowanloadOtherAgents",
    async(details) => {
        const data = await AdminAPI.get(`/getOtherAgents`,details); 
       return data;
    } 
);


// ======== Delete Other Agents =============

export const DeleteOtherAgent = createAsyncThunk(
  "DeleteOtherAgent",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteOtherAgent?otherAgentId=${details?.coachId}`);

     return data;
  } 
);


// =========== Get Other Agent profile By Id ==========

export const getOtherAgentdetails = createAsyncThunk(
  "getOtherAgentdetails",
  async(details) => {
      const data = await AdminAPI.get(`/getOtherAgentById?otherAgentId=${details?.id}`);

     return data;
  } 
);


// ======== Get all Teams =========  

export const AllTeams = createAsyncThunk(
  "AllTeams",
  async (details) => {
    let url = `/getTeams?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);

  // ========= Dowanload Teams xlsx file ===========

  export const DowanloadTeams = createAsyncThunk(
    "DowanloadTeams",
    async(details) => {
        const data = await AdminAPI.get(`/getTeams`,details); 
       return data;
    } 
);

// ======== Delete Team =============

export const Deleteteam = createAsyncThunk(
  "Deleteteam",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteTeam?teamId=${details?.id}`);
     return data;
  } 
);


// =========== Get Team profile By Id ==========

export const getTeamdetails = createAsyncThunk(
  "getTeamdetails",
  async(details) => {
      const data = await AdminAPI.get(`/getTeamById?teamId=${details?.id}`);

     return data;
  } 
);
