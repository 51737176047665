import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Users({ Active,InActive }) {
  const data = {
    labels: [
      `Active Agent ${Active}%`,
      `InActive Agent ${InActive}%`,
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [Active, InActive],
        backgroundColor: ["#8BB9D6", "#4C8BB4"],

        borderWidth: 1,
      },
    ],
  };

return (
  <>
<Doughnut className="gender-chart" data={data} />
</>
);
}
