import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ErrorMessage, Formik } from "formik";
import { verifyotp } from "../redux/actions/adminActions";

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .min(
      4,
      <span className="error-message">Otp must have atleast 4 numbers</span>
    )
    .required(<span className="error-message">Required</span>),
});

export default function LoginOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                try {
                  const data = await dispatch(
                    verifyotp({
                      otp: values.otp,
                      email: location?.state?.email,
                    })
                  );
                  console.log(data);
                  console.log(data?.payload?.data?.data?._id, "datata");

                  if (data?.payload?.data?.status === 200) {
                    toast.success(data?.payload?.data?.message);
                    console.log(data?.payload?.data?.message);
                    navigate("/reset-password", {
                      state: { email: data?.payload?.data?.data?.email },
                    });
                  } else {
                    toast.error("Incorrect OTP");
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({ values, handleSubmit, handleChange }) => (
                <Form>
                  <div className="login-cmn-box">
                    <div className="login-box-inner-wrap">
                      <h2 className="text-center">Enter OTP</h2>

                      <div className="form-set">
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Enter OTP</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Type OTP"
                            name="otp"
                            onChange={handleChange}
                          />
                          <ErrorMessage name="otp" component="div"/>
                        </Form.Group>
                      </div>
                      <button
                        variant="primary"
                        type="submit"
                        className="submit"
                        onClick={handleSubmit}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
