import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import PlayerNav from "../Components/PlayerNav";
import { useDispatch } from "react-redux";
import { getPlayersdetails } from "../redux/actions/adminActions";

export default function PlayerProfileHealth() {
  const [playerdata, setPlayerdata] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getPlayersdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setPlayerdata(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Player Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            {/* <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0 gap-3"
            >
              <div className="cmn-btn">
                <Link to="/PlayerProfileOverview">Overview</Link>
              </div>
              <div className="cmn-btn">
                <Link to="/PlayerProfileContract">Contract</Link>
              </div>
              <div className="cmn-btn" onClick={handleToprev}>
                <Link >statistics</Link>
              </div>
              <div className="cmn-btn">
                <Link to="/PlayerProfileHealth" className="active-btn-select">health</Link>
              </div>
              <div className="cmn-btn" onClick={handleProfileProposal}>
                <Link >Proposals</Link>
              </div>
            </Col> */}
            <PlayerNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row className="">
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4 customer-form-inner p-0">
                        <div className="">
                          <h3 className="">Current injury</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Injury Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.current_injuries_issues
                                      ?.length > 0
                                      ? playerdata.current_injuries_issues
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Date of coming back</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.date_of_coming_back
                                      ? new Date(
                                          playerdata?.date_of_coming_back
                                        )
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <h3 className="mt-3 mb-3">Past Injuries </h3>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Injury Name</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  value={
                                    playerdata?.past_injuries &&
                                    playerdata.past_injuries.length > 0
                                      ? playerdata.past_injuries.join(", ")
                                      : "N/A"
                                  }
                                  rows={3}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Health Problem</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  value={playerdata?.health_problems || "N/A"}
                                  rows={3}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
