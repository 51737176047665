import { configureStore } from '@reduxjs/toolkit'
import { DashboardSlice } from './reducer/dashboardSlice';
import UserManagementSlice, { usermgmtSlice } from './reducer/UserManagementSlice';



export const Store = configureStore({
    reducer: {
     dash:DashboardSlice.reducer,
     users:usermgmtSlice.reducer
    
    },
});