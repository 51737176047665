import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button,Modal } from "react-bootstrap";
import { AllCoaches, DeleteCoaches, DowanloadCoaches } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import Pagination from "../Components/Layout/pagination";
import { toast } from "react-toastify";

export default function Coaches() {
  const [page, setpage] = useState(1);

  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [CoachesList, setCoachesList] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showd, setshowd] = useState(false);
  const [coachid, setCoachId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };

    dispatch(AllCoaches(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setCoachesList(res?.payload?.data?.data?.coaches);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "error occur");
      });
  }, [page, limit, search, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadCoaches());
      console.log(response);

      const allData = response?.payload?.data?.data?.coaches;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleProfile = (id) => {
    navigate(`/coachesprofile-overview/${id}`);
  };


  const handleDelete = () => {
    dispatch(DeleteCoaches({ coachId: coachid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Coaches</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Profile Action</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select>
              </Col> */}
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">  Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Coach Name</th>
                <th>Agent Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {CoachesList?.map((res,index) => {
                 const serialNumber =
                 ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleProfile(res?._id)}
                      >
                        <b>{res?.full_name || "N/A"}</b>
                      </p>
                    </td>
                    <td>{res?.associate_agent_name || "N/A"}</td>
                    <td>
                      {res?.phone_number
                        ? res.country_code
                          ? `+${res.country_code} ${res.phone_number}`
                          : `${res.phone_number}`
                        : "N/A"}
                    </td>
                    <td>{res?.email || "N/A"}</td>

                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setshowd(true);
                          setCoachId(res?._id);
                        }}
                      >
                        Remove
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Coach ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}
