import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import PlayerNav from "../Components/PlayerNav";
import { getPlayersdetails } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";

export default function PlayerProfileContract() {
  const [playerdata, setPlayerdata] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getPlayersdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setPlayerdata(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Player Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <PlayerNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row className="">
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Current contract details</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Current Annual salary</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.current_contract || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Bonus</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.Bonuses || "N/A"}

                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Joining Date</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                value={ playerdata?.joined_date
                                    ? new Date(playerdata?.joined_date)
                                        .toISOString()
                                        .slice(0, 10)
                                        .replace(/-/g, "/")
                                    : "N/A"}

                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contract start date</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  
                                  value={ playerdata?.contract_start_date
                                    ? new Date(playerdata?.contract_start_date)
                                        .toISOString()
                                        .slice(0, 10)
                                        .replace(/-/g, "/")
                                    : "N/A"}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contract end date</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={ playerdata?.contract_end_date
                                    ? new Date(playerdata?.contract_end_date)
                                        .toISOString()
                                        .slice(0, 10)
                                        .replace(/-/g, "/")
                                    : "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Release clause</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.release_clouse === 0 ? "No" : "Yes"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Buy-cot clause</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.buyout_clouse === 0 ? "No" : "Yes"}
                                />
                              </Form.Group>
                            </Col>
                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">NEW CONTRACT REQUIREMENT</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Annual asking salary</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.new_contract_team_asking_amount || "N/A"}

                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contract Duration</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.new_contract_duration || "N/A"}

                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              {/* <Form.Group className="mb-3">
                                <Form.Label>Amount for Transfer</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  // value={playerdata?.}
                                />
                              </Form.Group> */}
                            </Col>

                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
