import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "../actions/adminActions";

export const DashboardSlice = createSlice({
    name:"DashboardSlice",
    initialState:{
        dashboarddata:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getDashboard.fulfilled,(state,action)=>{
            state.dashboarddata = action.payload
        })
    }
})

export default DashboardSlice.reducer