import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getOtherAgentdetails } from "../redux/actions/adminActions";
import Logo from "../Assets/Images/Profile-icon.png";

export default function OtherAssociateAgentsProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();
  const [Otheragentdata, setOtherAgentData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const getresponse = async () => {
      const data = await dispatch(getOtherAgentdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setOtherAgentData(data?.payload?.data?.data);
      }
    };
    getresponse();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Associate Agents Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/otherassociate-agent">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0 gap-3"
            >
              {/* <div className="cmn-btn">
                <Link to="#">Overview</Link>
              </div>
              <div className="cmn-btn">
                <Link to="#">Contract</Link>
              </div>
              <div className="cmn-btn">
                <Link to="#">Proposals</Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex">
                        <span>
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              Otheragentdata?.profile_image
                                ? `${url}${Otheragentdata?.profile_image}`
                                : Logo
                            }
                          />
                        </span>
                        <div className="figure-caption">
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Other Associate agents info</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      Other Associate agent
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        Otheragentdata?.associate_name || "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      Other Associate Agent ID
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={Otheragentdata?.unique_id || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        Otheragentdata?.role === 0
                                          ? "Players"
                                          : Otheragentdata?.role === 1
                                          ? "Technical/ Sport Directors"
                                          : Otheragentdata?.role === 2
                                          ? "Coaches/ Managers"
                                          : Otheragentdata?.role === 3
                                          ? "PlAssistant Coachesayers"
                                          : Otheragentdata?.role === 4
                                          ? "Trainers"
                                          : Otheragentdata?.role === 5
                                          ? "PlaFootball Analysts/ Scoutersyers"
                                          : Otheragentdata?.role === 6
                                          ? "PlNutritionistayers"
                                          : Otheragentdata?.role === 7
                                          ? "Doctors"
                                          : Otheragentdata?.role === 8
                                          ? "Lawyers"
                                          : Otheragentdata?.role === 9
                                          ? "Accountants"
                                          : "Others"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        Otheragentdata?.gender === 0
                                          ? "Male"
                                          : "Female"
                                      }
                                    />
                                  </Form.Group>
                                </Col>

                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={Otheragentdata?.team || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={Otheragentdata?.email || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        Otheragentdata?.phone_number
                                          ? Otheragentdata.country_code
                                            ? `+${Otheragentdata.country_code} ${Otheragentdata.phone_number}`
                                            : `${Otheragentdata.phone_number}`
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="default-links">
                          <ul>
                            <li>
                              <h6>Facebook</h6>
                              <p>{Otheragentdata?.facebook || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Instagram </h6>
                              <p>{Otheragentdata?.instagram || "N/A"}</p>

                            </li>
                            <li>
                              <h6>Snapchat</h6>
                              <p>{Otheragentdata?.snapchat || "N/A"}</p>

                            </li>
                            <li>
                              <h6>X </h6>
                              <p>{Otheragentdata?.twitter || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
