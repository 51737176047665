import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";
import CustomerProfile from "./pages/CustomerProfile";
import Players from "./pages/Players";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import OrderManagement from "./pages/OrderManagement";
import ManageChat from "./pages/ManageChat";
import AddProduct from "./pages/AddProduct";
import OrderView from "./pages/OrderView";
import PlayerProfileOverview from "./pages/PlayerProfileOverview";
import PlayerProfileContract from "./pages/PlayerProfileContract";
import PlayerProfileStatistics from "./pages/PlayerProfileStatistics";
import PlayerProfileHealth from "./pages/PlayerProfileHealth";
import PlayerProfileProposals from "./pages/PlayerProfileProposals";
import Directors from "./pages/Directors";
import DirectorsProfileOverview from "./pages/DirectorsProfileOverview";
import DirectorsProfileContract from "./pages/DirectorsProfileContract";
import DirectorsProfileProposals from "./pages/DirectorsProfileProposals";
import Coaches from "./pages/Coaches";
import CoachesProfileOverview from "./pages/CoachesProfileOverview";
import CoachesProfileContract from "./pages/CoachesProfileContract";
import CoachesProfileProposals from "./pages/CoachesProfileProposals";
import Teams from "./pages/Teams";
import TeamDetailsOverview from "./pages/TeamDetailsOverview";
import TeamDetailsPlayers from "./pages/TeamDetailsPlayers";
import TeamDetailsDirectors from "./pages/TeamDetailsDirectors";
import TeamDetailsCoaches from "./pages/TeamDetailsCoaches";
import AssociateAgent from "./pages/AssociateAgent";
import AssociateAgentsProfile from "./pages/AssociateAgentsProfile";
import OtherAssociateAgent from "./pages/OtherAssociateAgent";
import OtherAssociateAgentsProfile from "./pages/OtherAssociateAgentsProfile";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import AssociateAgentContract from "./pages/AssociateAgentsContract";
import AssociateAgentProposals from "./pages/AssociateAgentsPropasal";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/otp" element={<LoginOtp />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/manage-chat" element={<ManageChat />} />
            <Route path="/OrderManagement" element={<OrderManagement />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/support" element={<Support />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/customer-profile/:id" element={<CustomerProfile />} />
            <Route path="/Supportchat" element={<Supportchat />} />
            <Route path="/CreateAdmin" element={<CreateAdmin />} />
            <Route path="/players" element={<Players />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/add-notification" element={<AddNotification />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/OrderView" element={<OrderView />} />
            <Route path="/AddProduct" element={<AddProduct />} />
            
            <Route
              path="/playerprofile-overview/:id"
              element={<PlayerProfileOverview />}
            />
            <Route
              path="/playerprofile-contract/:id"
              element={<PlayerProfileContract />}
            />
            <Route
              path="/playerprofile-statistics/:id"
              element={<PlayerProfileStatistics/>}
            />
            <Route
              path="/playerprofile-health/:id"
              element={<PlayerProfileHealth />}
            />
            <Route
              path="/playerprofile-proposals/:id"
              element={<PlayerProfileProposals />}
            />


            <Route path="/directors" element={<Directors />}/>
            <Route
              path="/directorsprofile-overview/:id"
              element={<DirectorsProfileOverview />}
            />
            <Route
              path="/directorsprofile-contract/:id"
              element={<DirectorsProfileContract />}
            />
            <Route
              path="/directorsprofile-proposals/:id"
              element={<DirectorsProfileProposals />}
            />

            
            <Route path="/Coaches" element={<Coaches />} />
            <Route
              path="/coachesprofile-overview/:id"
              element={<CoachesProfileOverview />}
            />
            <Route
              path="/coachesprofile-contract/:id"
              element={<CoachesProfileContract/>}
            />
            <Route
              path="/coachesprofile-proposals/:id"
              element={<CoachesProfileProposals/>}
            />

            <Route path="/teams" element={<Teams/>} />
            <Route
              path="/teamdetails-overview/:id"
              element={<TeamDetailsOverview />}
            />
            <Route
              path="/teamdetails-players/:id"
              element={<TeamDetailsPlayers />}
            />
            <Route
              path="/teamdetails-directors/:id"
              element={<TeamDetailsDirectors />}
            />
            <Route
              path="/teamdetails-coaches/:id"
              element={<TeamDetailsCoaches />}
            />

            
            <Route path="/associate-agent" element={<AssociateAgent />} />
            <Route
              path="/associateagents-profile/:id"
              element={<AssociateAgentsProfile />}
            />
            {/* <Route
              path="/associateagents-contract/:id"
              element={<associateagent-contract />}
            />
            <Route
              path="/associateagents-proposals/:id"
              element={<AssociateAgentProposals/>}
            /> */}
            <Route
              path="/otherassociate-agent"
              element={<OtherAssociateAgent/>}
            />
            <Route
              path="/otherassociateagents-profile/:id"
              element={<OtherAssociateAgentsProfile />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
