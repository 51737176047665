import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure } from "react-bootstrap";
import TeamProfileNav from "../Components/TeamProfileNav";
import { useDispatch } from "react-redux";
import { getTeamdetails } from "../redux/actions/adminActions";

export default function TeamDetailsDirectors() {
  const { id } = useParams();
  const [directorsData, setDirectorsData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getTeamdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setDirectorsData(data?.payload?.data?.data?.directors);
      }
    };
    getuserdata();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Team Details</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Teams">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>

            <TeamProfileNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid>
        {/* <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Director name</th>
                <th>Director ID</th>
                <th>Image</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {directorsData?.map((res, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {/* <Link to="/TeamDetailsOverview"> */}
                      {res?.director_name || "N/A"}
                      {/* </Link> */}
                    </td>
                    <td> {res?.director_id || "N/A"} </td>
                    <td className="user-img">
                      <img src={require("../Assets/Images/user.png")} />
                    </td>
                    {/* <td>
                  <Button className="account-btn common-colr-btn">
                    Remove
                  </Button>{" "}
                </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  );
}
