import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const IntrestedTeamsFields = (props) => {
  return (
    <Row>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>Team name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={props?.interested_team_name || "N/A"}
          />
        </Form.Group>
      </Col>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>division</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={props?.interested_team_league || "N/A"}
          />
        </Form.Group>
      </Col>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={props?.interested_team_country || "N/A"}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default IntrestedTeamsFields;
