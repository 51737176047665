import React from "react";
import { Col } from "react-bootstrap";
import { Form } from "react-bootstrap";

const CommanFields = (props) => {
  return (
    <>
    <div>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>Team name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={props?.proposed_team_name || "N/A"}
          />
        </Form.Group>
      </Col>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>division</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={
              props?.proposed_team_league === 0
                ? " Premier/Top League"
                : props?.proposed_team_league === 1
                ? "1st Division"
                : props?.proposed_team_league === 2
                ? "2nd Division"
                : props?.proposed_team_league === 3
                ? "3nd Division"
                : "4nd Division"
            }
          />
        </Form.Group>
      </Col>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={props?.proposed_team_country || "N/A"}
          />
        </Form.Group>
      </Col>
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>Associate agent</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Here"
            value={props?.proposed_associate_agent_name || "N/A"}
          />
        </Form.Group>
      </Col>
    </div>
    </>
  );
};

export default CommanFields;
