import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
export default function OrderView() {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Order View</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/OrderManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={8}>
            <div className="user-profile-main">
              <Row>
                <Col lg={12}>
                  <Figure className="d-flex">
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src={require("../Assets/Images/user.png")}
                    />
                    <Figure.Caption>
                      <div className="customer-form-new customer-form-inner p-0 border-none mb-4">
                        <div className="">
                          <h3>Order info</h3>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Order number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>No of Packages </Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Type Email Here"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Customer name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Email Here"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Deliver by</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                              </Form.Group>
                            </Col>
                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                        </div>
                      </div>
                    </Figure.Caption>
                  </Figure>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={4}>
            {" "}
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>Order status</h2>
                <Form.Select aria-label="Default select example">
                  <option>Update status</option>
                  <option value="1">Published</option>
                </Form.Select>
              </div>
            </div>
            <div className="product-overview-right mt-4">
              <div className="product-overview-box">
                <div className="order-list-item">
                  <h2>Package 1</h2>
                  <div className="order-size-pack">
                    <img src={require("../Assets/Images/order-img.png")} />
                    <div className="">
                      <div>
                        <b>Petrol</b>
                      </div>
                      <div>5 L</div>
                      <div>Qty: 2</div>
                    </div>
                  </div>
                </div>
                <div className="order-list-item">
                  <h2>Package 1</h2>
                  <div className="order-size-pack">
                    <img src={require("../Assets/Images/order-img.png")} />
                    <div className="">
                      <div>
                        <b>Petrol</b>
                      </div>
                      <div>5 L</div>
                      <div>Qty: 2</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col lg={8} className="d-flex justify-content-end mt-2 mb-3">
            {" "}
            <div className="cmn-btn mt-4 mb-3">
              <Link to="#">Add BLog</Link>
            </div>
          </Col> */}
        </Row>
      </Container>
    </Layout>
  );
}
