import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"

const PlayerNav =(props)=>{
    const {id} = props

    const Activenav = {
        'Overview':`/playerprofile-overview/${id}`,
        'Contract':`/playerprofile-contract/${id}`,
        'Statistics':`/playerprofile-statistics/${id}`,
        'Health':`/playerprofile-health/${id}`,
        'Proposals':`/playerprofile-proposals/${id}`,
    }

    return(
        <>
           <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0 gap-3"
            >
            {Object.entries(Activenav).map((value)=> {
                return <div className="cmn-btn" >
                <Link to={value[1]}  className={`${value[1] === window.location.pathname && 'active-btn-select'}`}>
                  {value[0]}
                </Link>
              </div> 
            }
            )}      
            </Col>
        </>
    )
}
export default PlayerNav