import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import PlayerNav from "../Components/PlayerNav";
import { useDispatch } from "react-redux";
import { getPlayersdetails } from "../redux/actions/adminActions";

export default function PlayerProfileStatistics() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [playerdata, setPlayerdata] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getPlayersdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setPlayerdata(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Player Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>

            <PlayerNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row className="">
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Statistics</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Appearances</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.total_appearence || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Starting eleven </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.total_appearence || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Enter as Substitute</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.enter_as_substitution || "NA"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Out as Substitute</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.out_as_substitution || "NA"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Goals</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.goals || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Assists</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.assists || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Goals Conceded</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.goals_cancelled || "NA"}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Clean sheets </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.clean_sheets || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Red Cards</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.red_card || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Yellow Cards</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.yellow_card || "NA"}
                                />
                              </Form.Group>
                            </Col>
                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* <Col lg={4}>
              <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Advanced STATISTICS</h2>
                        <div className="default-links">
                          <ul>
                            <li>
                              <h6>Click <Link to="">here</Link> to get advanced Statistics </h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
