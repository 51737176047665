import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

export default function Pagination({ totalstuff,limit,setpages,search }) {
  const handlePageChange = (selectedPage) => {
    setpages(selectedPage + 1); // ReactPaginate uses zero-based index, so we add 1
  };

  // const pageCount = search ? 1 : totalstuff;
  const pageCount = Math.ceil(totalstuff / limit);
  // debugger

  

  return (
    <div className="pagination-wrapper next-btn-fix set-pagi-btm">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"next"}
        onPageChange={({ selected }) => handlePageChange(selected)}
        // onPageChange={(e) => setpages(e?.selected + 1)}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        // pageCount={search? 1 :totalstuff}
        previousLabel={"prev"}
        marginPagesDisplayed={1}
        renderOnZeroPageCount={null}
       
      />
    </div>
  );
}


