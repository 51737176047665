import React, { useEffect, useState } from "react";
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import DirectorsNav from "../Components/DirectorsNav";
import { getDirectorsdetails } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/Profile-icon.png";
import ModalImage from "react-modal-image";
import { calculateAge } from "../util/HelperFunction";


export default function DirectorsProfileOverview() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();

  const [directorsData, setDirectorsdata] = useState("");
  console.log(directorsData);
  const dispatch = useDispatch();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getDirectorsdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setDirectorsdata(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  const images = directorsData?.photos?.map((photo) => `${url}${photo}`) || [];

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Director's Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/directors">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <DirectorsNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex">
                        <span className="">
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              directorsData?.profile_image
                                ? `${url}${directorsData?.profile_image}`
                                : Logo
                            }
                          />
                        </span>
                        <div className="figure-caption">
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Player info</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Director name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={directorsData?.full_name || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Director ID</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={directorsData?.unique_id || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Agent</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        directorsData?.associate_agent_name ||
                                        "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Profile</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.gender === 0
                                      ? "Male"
                                      : "Female"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Age</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={calculateAge
                                    (directorsData?.dob)}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Availability</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={directorsData?.availability_status
                                    ?.map((status) =>
                                      status === 0
                                        ? "Free"
                                        : status === 1
                                        ? "Not Free"
                                        : status === 2
                                        ? "On loan"
                                        : status === 3
                                        ? "Free in the coming transfer period"
                                        : "Open for discussions"
                                    )
                                    .join(", ")}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Language</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={directorsData?.languages?.map((Lang) =>
                                    Lang === 0
                                      ? "Afrikaans"
                                      : Lang === 1
                                      ? "Albanian"
                                      : Lang === 2
                                      ? "Amharic"
                                      : "Akan"
                                  ).join(', ')}
                                />
                              </Form.Group>
                            </Col>

                            
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Nationality</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={directorsData?.nationality
                                    ?.map((NATIONALITY) =>
                                      NATIONALITY === 0
                                        ? "Angolan"
                                        : NATIONALITY === 1
                                        ? "American"
                                        : NATIONALITY === 2
                                        ? "Andorran"
                                        : "Antiguans"
                                    )
                                    .join(", ")}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Previous Status</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.previous_status?.length > 0
                                      ? directorsData.previous_status
                                          .map((res) =>
                                            res === 0
                                              ? "Technical/sport director"
                                              : res === 1
                                              ? "Coach"
                                              : res === 2
                                              ? "Scouter"
                                              : res === 3
                                              ? "Football analyst"
                                              : res === 4
                                              ? "Football player"
                                              : "Football specialist"
                                          )
                                          .join(", ")
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Coaching Licenses</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.coaching_licenses?.length > 0
                                      ? directorsData.coaching_licenses

                                          .map((res) =>
                                            res === 0
                                              ? "None"
                                              : res === 1
                                              ? " UEFA PRO"
                                              : res === 2
                                              ? "UEFA A"
                                              : res === 3
                                              ? "UEFA B"
                                              : res === 4
                                              ? "UEFA C"
                                              : res === 5
                                              ? "Goalkeeper A"
                                              : res === 6
                                              ? "Goalkeeper B"
                                              : res === 7
                                              ? "Elite Youth A"
                                              : "Youth B"
                                          )
                                          .join(", ")
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <h3 className="mb-3 mt-4">Current team</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Team name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.current_team_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>country</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.current_team_country || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Contact</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.phone_number
                                      ? directorsData.country_code
                                        ? `+${directorsData.country_code} ${directorsData.phone_number}`
                                        : `${directorsData.phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={directorsData?.email || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Home address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={directorsData?.address || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Director lawyer</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.director_lawyer_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contact number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.director_lawyer_phone_number
                                      ? directorsData.director_lawyer_country_code
                                        ? `+${directorsData.director_lawyer_country_code} ${directorsData.director_lawyer_phone_number}`
                                        : `${directorsData.director_lawyer_phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Managing company</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.director_managing_company_name ||
                                    "NA"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contact number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    directorsData?.director_managing_company_phone_number
                                      ? directorsData.director_managing_company_country_code
                                        ? `+${directorsData.director_managing_company_country_code} ${directorsData.director_managing_company_phone_number}`
                                        : `${directorsData.director_managing_company_phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="default-links">
                          <ul>
                            <li>
                              <h6>Facebook</h6>
                              <p>{directorsData?.facebook || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Instagram </h6>
                              <p>{directorsData?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Snapchat</h6>
                              <p>{directorsData?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h6>X </h6>
                              <p>{directorsData?.twitter || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {/* <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Photos</h2>
                        <Row>
                        
                          {images.length > 0 ? (
                          images.map((img, index) => (
                            <div key={index} className="mb-3">
                              <ModalImage
                                small={img}
                                large={img}
                                alt={`Photo ${index}`}
                                className="profile-add-img"
                              />
                            </div>
                          ))
                        ) : (
                          <p>No Picture Found</p>
                        )}
                        </Row>
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">CAREER TROPHIES</h2>
                        <div className="default-links">
                          {directorsData?.carrier_trophies?.map((trophies) => {
                            console.log(trophies);
                            return (
                              <ul className="m-0">
                                <li>
                                  <h6>
                                    {trophies.trophy === 0
                                      ? "Youth Trophies"
                                      : trophies.trophy === 1
                                      ? "Local Championships"
                                      : trophies.trophy === 2
                                      ? "Local Cups"
                                      : trophies.trophy === 3
                                      ? "European Champions League"
                                      : trophies.trophy === 4
                                      ? "European Cups"
                                      : "National Team Cups"}
                                  </h6>
                                 
                                </li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {/* <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">CV</h2>
                        <Row>
                          <Col lg={6} className="mb-3   ">
                            <img
                              className="profile-add-img"
                              src={require("../Assets/Images/profile-img.png")}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
