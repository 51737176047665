import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import PlayerNav from "../Components/PlayerNav";
import { useDispatch } from "react-redux";
import { getPlayersdetails } from "../redux/actions/adminActions";

export default function PlayerProfileProposals() {
  const [Proposedplayer, setProposedplayer] = useState([]);
  const [Intrested, setIntrested] = useState([]);
  console.log(Proposedplayer);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getPlayersdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setProposedplayer(data?.payload?.data?.data?.proposed_team);
        setIntrested(data?.payload?.data?.data?.interested_team);
      }
    };
    getuserdata();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Player Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>

            <PlayerNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row className="">
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4 ">
                        <div className="">
                          <h3 className="">
                            Team that the player has been proposed
                          </h3>
                          <Row>
                            {Proposedplayer?.map((res) => {
                              console.log(res);
                              return (
                                <div>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Team name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type Here"
                                        value={res?.proposed_team_name || "N/A"}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>division</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type Here"
                                        value={
                                          res?.proposed_team_league === 0
                                            ? " Premier/Top League"
                                            : res?.proposed_team_league === 1
                                            ? "1st Division"
                                            : res?.proposed_team_league === 2
                                            ? "2nd Division"
                                            : res?.proposed_team_league === 3
                                            ? "3nd Division"
                                            : "4nd Division"
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type Here"
                                        value={
                                          res?.proposed_team_country || "N/A"
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Associate agent</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type Here"
                                        value={
                                          res?.proposed_associate_agent_name
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                </div>
                              );
                            })}

                            <h3 className="mt-3 mb-3">
                              Team that are interested for the player
                            </h3>
                            {Intrested?.map((res) => {
                              return (
                                <div>
                                  <Row>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Team name</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Type Here"
                                          value={
                                            res?.interested_team_name || "N/A"
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>division</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Type Here"
                                          value={
                                            res?.interested_team_league === 0
                                              ? " Premier/Top League"
                                              : res?.interested_team_league ===
                                                1
                                              ? "1st Division"
                                              : res?.interested_team_league ===
                                                2
                                              ? "2nd Division"
                                              : res?.interested_team_league ===
                                                3
                                              ? "3nd Division"
                                              : "4nd Division"
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Type Here"
                                          value={
                                            res?.interested_team_country || "N/A"
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
