import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import CoachNav from "../Components/CoachNav";
import { getCoachdetails } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import CommanFields from "../Components/CommanFields";
import IntrestedTeamsFields from "../Components/IntrestedTeamsFields";

export default function CoachesProfileProposals() {
  const [CoachProposal, setCoachProposal] = useState({});
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getCoachdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setCoachProposal(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  console.log(CoachProposal,'CoachProposal');

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Coach’s Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Coaches">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>

            <CoachNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row className="">
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">
                            Team that the player has been proposed
                          </h3>
                          <Row>
                            {(Array.isArray(CoachProposal?.proposed_team) && CoachProposal?.proposed_team.length)  
                             ? CoachProposal?.proposed_team?.map((res) => (
                             <CommanFields {...res}/>
                            )):
                             <>
                            <p>No Data Found</p>
                            </>}
                            <h3 className="mt-3 mb-3">
                              Team that are interested for the Director
                            </h3>
                            {(Array.isArray(CoachProposal?.interested_team) && CoachProposal?.interested_team.length)  
                             ? CoachProposal?.interested_team?.map((res) => (
                             <IntrestedTeamsFields {...res}/>
                            )):
                             <>
                           <p>No Data Found</p>
                            </>}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
