import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../redux/actions/adminActions";
import { toast } from "react-toastify";


const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
});

export default function ForgotPassword() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={async(values) => {
                try{
                  const data = await dispatch(forgotPassword(values));
                  console.log( data);
                  if(data?.payload?.data?.status === 200){
                   toast.success(data?.payload?.data?.message)
                        setTimeout(() => {
                         toast.dismiss();
                         navigate('/otp', { state: { email: values.email } });
                        }, 1000);
                  }else{
                    toast.error(data?.payload?.data?.message)
                  } 
                  }catch (error){
                   console.log(error);
                  }
              }}
            >
              {({ values, handleSubmit, handleChange }) => (
                <Form>
                  <div className="login-cmn-box">
                    <div className="login-box-inner-wrap">
                      <h2 className="text-center">Forgot Password</h2>
                      <div className="form-set">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type Email"
                            name="email"
                            onChange={handleChange}
                          />
                          <ErrorMessage name="email" component="div"/>
                        </Form.Group>
                      </div>
                      <button
                        variant="primary"
                        type="submit"
                        className="submit"
                        onClick={handleSubmit}
                      >
                        Send OTP
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
